import useMarqueeJobs from "@/hooks/landing/useMarqueeJobs";
import FlexCol from "@/ui/FlexCol";
import FlexRow from "@/ui/FlexRow";
import MobileLandingSubPageLayout from "@/ui/MobileLandingSubPageLayout";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Carousel, Flex, Typography } from "antd";
import cs from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { trackEvent } from "@/utils";

import { MOBILE_LANDING_ANIMATION_THRESHOLD } from "@/constants";
import { SECOND } from "@/constants/jobs";
import { DETAIL_PATHNAME } from "@/constants/jobs/joblist";

import CompanyLogo from "../LandingCompanyLogo";
import styles from "./index.module.less";

const MobileLandingPageTwo = () => {
  const router = useRouter();
  const jobs = useMarqueeJobs();

  const [ref, entry] = useIntersectionObserver({
    threshold: 1,
    root: null,
    rootMargin: "-2px",
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      trackEvent("landing_exposure_page2");
    }
  }, [entry?.isIntersecting]);

  return (
    <MobileLandingSubPageLayout
      containerClassName={cs(styles["second-page-container"])}
      className={styles["second-page-layout"]}
      threshold={MOBILE_LANDING_ANIMATION_THRESHOLD}
    >
      <FlexCol size={"1.5rem"} className={styles["main-content"]} ref={ref as any}>
        <h3 className={styles["main-title"]}>
          Never Miss Out On New Jobs Again.
          <br />
          Join The Largest Job Board!
        </h3>
        <FlexRow size={"1rem"}>
          <div className={styles["main-job-block"]}>
            <div className={styles["main-job-block-count"]}>400,000+</div>
            <div className={styles["main-job-block-description"]}>{`Today's new jobs`}</div>
          </div>
          <div className={styles["main-job-block"]}>
            <div className={styles["main-job-block-count"]}>8,000,000+</div>
            <div className={styles["main-job-block-description"]}>Total jobs</div>
          </div>
        </FlexRow>

        <Carousel
          effect="fade"
          infinite
          autoplay
          autoplaySpeed={SECOND * 3}
          dots={{
            className: styles["carousel-dots"],
          }}
        >
          {Array.from({ length: Math.ceil(Math.min(jobs.length, 9) / 3) }).map((_, index) => {
            return (
              <div key={index}>
                <FlexCol size={"0.5rem"} className={styles["job-cards-container"]}>
                  {jobs.slice(index * 3, (index + 1) * 3).map((job, index) => (
                    <Link
                      className={styles["main-job-card"]}
                      key={index}
                      href={{
                        pathname: `${DETAIL_PATHNAME}/${job?.jobResult?.jobId}`,
                        query: router.query,
                      }}
                      target="_blank"
                    >
                      <CompanyLogo width={40} height={40} logoSrc={job?.jobResult?.jdLogo} />
                      <Flex vertical>
                        <Typography.Text className={styles["main-job-card-company-info"]}>
                          {job?.companyResult?.companyName}{" "}
                          <Typography.Text className={styles["main-job-card-extra-info"]}>
                            · {job?.jobResult?.publishTimeDesc.split(" ")[0]}{" "}
                          </Typography.Text>
                        </Typography.Text>
                        <Typography.Paragraph className={styles["main-job-card-title"]} ellipsis>
                          {job?.jobResult?.jobTitle}
                        </Typography.Paragraph>
                      </Flex>
                    </Link>
                  ))}
                </FlexCol>
              </div>
            );
          })}
        </Carousel>
      </FlexCol>
    </MobileLandingSubPageLayout>
  );
};

export default MobileLandingPageTwo;
