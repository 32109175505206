import { LandingPageFive } from "@/types/jobs";
import GlobalFooter from "@/ui/GlobalFooter";
import { Layout } from "antd";
import cs from "classnames";

import MobileLandingPageOne from "@/components/MobileLanding/MobileLandingPageOne";
import MobileLandingPageTwo from "@/components/MobileLanding/MobileLandingPageTwo";

import MobileLandingPageEight from "./MobileLandingPageEight";
import MobileLandingPageFive from "./MobileLandingPageFive";
import MobileLandingPageFour from "./MobileLandingPageFour";
import MobileLandingPageSeven from "./MobileLandingPageSeven";
import MobileLandingPageSix from "./MobileLandingPageSix";
import MobileLandingPageThree from "./MobileLandingPageThree";
import styles from "./index.module.less";

const MobileLandingPage: React.FC<LandingPageFive> = ({ recommendLinks }) => {
  return (
    <Layout className={cs(styles["landing-page-layout"], styles["landing-page-layout-overwrite"])}>
      <MobileLandingPageOne />
      <MobileLandingPageTwo />
      <MobileLandingPageThree />
      <MobileLandingPageFour />
      <MobileLandingPageFive />
      <MobileLandingPageSix />
      <MobileLandingPageSeven />
      <MobileLandingPageEight recommendLinks={recommendLinks} />
      <GlobalFooter />
    </Layout>
  );
};

export default MobileLandingPage;
