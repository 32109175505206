import FlexCol from "@/ui/FlexCol";
import FlexRow from "@/ui/FlexRow";
import MobileLandingSubPageLayout from "@/ui/MobileLandingSubPageLayout";
import { Flex, Typography } from "antd";
import Image from "next/image";

import { MOBILE_LANDING_ANIMATION_THRESHOLD } from "@/constants";
import { LANDING_CHAT_ITEMS } from "@/constants/landing/landing";

import styles from "./index.module.less";

type ChatItemProps = {
  paragraph: string;
  name: string;
  avatar: string;
  title: string | React.ReactNode;
};

const ChatItem: React.FC<ChatItemProps> = ({ paragraph, name, avatar, title }) => {
  return (
    <Flex vertical className={styles["chat-item"]} gap={24}>
      <Flex className={styles["chat-item-header"]} justify="space-between">
        <Image src={"/newimages/public/quote.svg"} width={16} height={16} alt="quote" />

        <Flex gap={12} className={styles["chat-item-header-content"]} align="center">
          <Flex vertical>
            <Typography.Title className={styles["chat-username"]} level={3}>
              {name}
            </Typography.Title>
            <Typography.Text className={styles["chat-job-title"]}>{title}</Typography.Text>
          </Flex>
          <Image
            className={styles["chat-avatar"]}
            src={avatar}
            width={48}
            height={48}
            alt="avatar"
          />
        </Flex>
      </Flex>
      <Typography.Paragraph className={styles["chat-item-paragraph"]}>
        {paragraph}
      </Typography.Paragraph>
    </Flex>
  );
};

const MobileLandingPageFive = () => {
  return (
    <MobileLandingSubPageLayout
      containerClassName={styles["fifth-page-container"]}
      className={styles["fifth-page-layout"]}
      threshold={MOBILE_LANDING_ANIMATION_THRESHOLD}
    >
      <div className={styles["main-content"]}>
        <Typography.Title level={2} className={styles["main-content-title"]}>
          Trusted by Industry Leaders
        </Typography.Title>

        <FlexCol className={styles["main-content-sub-container"]} size={"0.5rem"}>
          {LANDING_CHAT_ITEMS.map((item, index) => {
            return <ChatItem key={index} {...item} />;
          })}
        </FlexCol>
      </div>
    </MobileLandingSubPageLayout>
  );
};

export default MobileLandingPageFive;
