import FlexCol from "@/ui/FlexCol";
import MobileLandingSubPageLayout from "@/ui/MobileLandingSubPageLayout";
import SimpleButton from "@/ui/SimpleButton";
import { Typography } from "antd";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useCallback, useState } from "react";

import { trackEvent } from "@/utils";

import { MOBILE_LANDING_ANIMATION_THRESHOLD } from "@/constants";

import styles from "./index.module.less";

const MobileSignInModal = dynamic(() => import("@/components/Landing/MobileSignInModal"), {
  ssr: false,
});
const MobileSignUpModal = dynamic(() => import("@/components/Landing/MobileSignUpModal"), {
  ssr: false,
});
const contentList = [
  {
    icon: "/newimages/landing/aistar_landing.svg",
    embed: "AI Job Match",
    desc: "Job searching is already hard! Increase your odds with AI matched Jobs",
    list: [
      "Apply only to Jobs you are qualified for",
      "Discover matched jobs based on your skills, not only titles",
      "Say goodbye to fake jobs",
      "Apply early with our custom job alerts",
    ],
    picture: "/newimages/mobile/mobile_n1.png",
    button: "Start Matching",
  },
  {
    icon: "/newimages/landing/edit_landing.svg",
    embed: "Resume AI",
    desc: "Stand out from the crowd with a top notch resume",
    list: [
      "Get a professional quality resume in minutes, not hours",
      "Keep tailoring your resume with AI and catch HR’s eyes in 6 seconds",
      "Rest easy knowing your resume will be ATS compatible",
    ],
    picture: "/newimages/mobile/mobile_n2.png",
    button: "Improve My Resume",
  },
  {
    icon: "/newimages/landing/social_landing.svg",
    embed: "Insider connections",
    desc: "Network like a pro with our recommended insider connections. Connect, get referrals, and land interviews!",
    list: [
      "Increase your chances of landing an interview by 4X with insider referrals",
      "Easily discover alumni and past colleagues within your target company",
      "Gain access to key connections, such as hiring managers and direct reports",
      "Easily personalize your cold outreach message with our custom templates",
    ],
    picture: "/newimages/mobile/mobile_n3.png",
    button: "Get Connected",
  },
  {
    icon: "/newimages/landing/chatbot_landing.svg",
    embed: "Orion, your AI Copilot",
    desc: "Job searching can be lonely. Chat with Orion for 24/7 genuine career support",
    list: [
      "Focus your efforts with a more tailored list of jobs",
      "Wow in your interviews with specific company insights",
      "Understand why you are a good fit for a role",
      "Stuck in your job search or career? Get personalized guidance and coaching",
    ],
    picture: "/newimages/mobile/mobile_n4.png",
    button: "Ask Orion",
  },
];

const MobileLandingPageFour = () => {
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const onShowSignUpModal = useCallback(() => {
    setSignUpModalOpen(true);
    trackEvent("mobile_landing_2nd_cta_click", {
      scene: "main_landing",
    });
  }, []);

  const onCloseSignInModal = useCallback(() => {
    setSignInModalOpen(false);
  }, []);

  const onCloseSignUpModal = useCallback(() => {
    setSignUpModalOpen(false);
  }, []);

  return (
    <>
      <MobileSignUpModal
        open={signUpModalOpen}
        onCancel={onCloseSignUpModal}
        onClickSignIn={() => {
          setSignUpModalOpen(false);
          setSignInModalOpen(true);
        }}
      />

      <MobileSignInModal
        open={signInModalOpen}
        onCancel={onCloseSignInModal}
        onClickSignUp={() => {
          setSignInModalOpen(false);
          setSignUpModalOpen(true);
        }}
      />

      <MobileLandingSubPageLayout
        containerClassName={styles["fourth-page-container"]}
        className={styles["fourth-page-layout"]}
        threshold={MOBILE_LANDING_ANIMATION_THRESHOLD}
      >
        <div className={styles["main-content"]}>
          <Typography.Title className={styles["main-content-title"]} level={2}>
            AI features
          </Typography.Title>

          <FlexCol size={0}>
            {contentList.map((item, index) => (
              <FlexCol className={styles["main-content-block"]} key={index} size={"1.5rem"}>
                <FlexCol size={"0.5rem"}>
                  <Image src={item.icon} width={32} height={32} alt="icon" />
                  <Typography.Paragraph className={styles["sub-content-title"]}>
                    <b>{item.embed}</b> / <span>{item.desc}</span>
                  </Typography.Paragraph>
                  <ul className={styles["sub-content-list"]}>
                    {item.list.map((content, i) => (
                      <li key={i}>{content}</li>
                    ))}
                  </ul>
                </FlexCol>

                <Image
                  className={styles["sub-content-img"]}
                  src={item.picture}
                  width={326}
                  height={326}
                  alt="pic"
                />

                <SimpleButton
                  type="primary"
                  onClick={onShowSignUpModal}
                  className={styles["mobile-sign-up-button"]}
                >
                  {item.button}
                </SimpleButton>
              </FlexCol>
            ))}
          </FlexCol>
        </div>
      </MobileLandingSubPageLayout>
    </>
  );
};

export default MobileLandingPageFour;
