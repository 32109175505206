import { RecommendPage } from "@/types/jobs";
import { Space, Tag } from "antd";
import cs from "classnames";
import Link from "next/link";

import { trackEvent } from "@/utils";

import styles from "./index.module.less";

type RecommendLinksProps = {
  recommendLinks: RecommendPage[];
  className?: string;
  isMobile?: boolean;
};

const RecommendVisitorLinks: React.FC<RecommendLinksProps> = ({
  recommendLinks,
  isMobile = false,
  className,
}) => {
  return (
    <>
      <Space
        size={8}
        wrap
        className={cs(styles["recommend-links"], className, { [styles["mobile"]]: isMobile })}
      >
        {recommendLinks.slice(0, 10).map((item: RecommendPage) => (
          <Link
            key={item.name}
            href={item.link}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackEvent("landing_job_list_click", {
                scene: "landing_page",
                platform: isMobile ? "mobile" : "pc",
              });
            }}
          >
            <Tag className={styles["recommend-links-item"]}>{item.name}</Tag>
          </Link>
        ))}
      </Space>
      <div className={styles["crawler-link"]}>
        {recommendLinks
          .slice(10)
          .reverse()
          .map((item: RecommendPage) => (
            <Link key={item.name} href={item.link} target="_blank" rel="noreferrer">
              {item.name}
            </Link>
          ))}
      </div>
    </>
  );
};

export default RecommendVisitorLinks;
