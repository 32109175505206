import FeaturedByOpenai from "@/ui/FeaturedByOpenai";
import MobileLandingHeader from "@/ui/MobileLandingHeader";
import SimpleButton from "@/ui/SimpleButton";
import { useIsClient } from "@uidotdev/usehooks";
import { useTimeout } from "ahooks";
import cs from "classnames";
import dynamic from "next/dynamic";
import { useCallback, useState } from "react";
import ReactPlayer from "react-player";

import { trackEvent } from "@/utils";

import { LANDING_YOUTUBE_VIDEO_LINK } from "@/constants";
import { SECOND } from "@/constants/jobs";

import styles from "./index.module.less";

const MobileSignInModal = dynamic(() => import("@/components/Landing/MobileSignInModal"), {
  ssr: false,
});
const MobileSignUpModal = dynamic(() => import("@/components/Landing/MobileSignUpModal"), {
  ssr: false,
});
const MobileLandingPageOne = () => {
  const [showCover, setShowCover] = useState(true);

  useTimeout(() => {
    setShowCover(false);
  }, SECOND * 3);

  const isClient = useIsClient();

  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const onShowSignUpModal = useCallback(() => {
    setSignUpModalOpen(true);
    trackEvent("mobile_tryforfree_click", {
      scene: "main_landing",
    });
  }, []);

  const onCloseSignInModal = useCallback(() => {
    setSignInModalOpen(false);
  }, []);

  const onCloseSignUpModal = useCallback(() => {
    setSignUpModalOpen(false);
  }, []);

  return (
    <>
      <MobileSignUpModal
        open={signUpModalOpen}
        onCancel={onCloseSignUpModal}
        onClickSignIn={() => {
          setSignUpModalOpen(false);
          setSignInModalOpen(true);
        }}
      />

      <MobileSignInModal
        open={signInModalOpen}
        onCancel={onCloseSignInModal}
        onClickSignUp={() => {
          setSignInModalOpen(false);
          setSignUpModalOpen(true);
        }}
      />

      <div id="firstpage" className={cs(styles["first-page-container"])}>
        <MobileLandingHeader />
        <div className={styles["landing-v3-content"]}>
          <FeaturedByOpenai />
          <h1 className={styles["main-title"]}>
            No More Solo Job Hunting
            <p className={styles.emphasis}>Do it with AI Copilot</p>
          </h1>
          <h2 className={styles["sub-title"]}>
            Land more interviews with AI-powered job matches, tailored resumes, and insider
            connections in less than 1 min!
          </h2>

          <SimpleButton
            type="primary"
            onClick={onShowSignUpModal}
            className={styles["mobile-sign-up-button"]}
          >
            Try Jobright for Free
          </SimpleButton>
        </div>
        <div className={styles["react-player-wrapper"]}>
          {isClient &&
            (showCover ? (
              <div className={styles["react-player-cover"]}></div>
            ) : (
              <ReactPlayer
                loop
                url={LANDING_YOUTUBE_VIDEO_LINK}
                width="22.7778rem"
                height="12.8125rem"
                style={{
                  borderRadius: 8,
                  backgroundImage: `url("/newimages/landing/jobs_normal.jpg")`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className={styles["react-player"]}
                config={{
                  youtube: {
                    playerVars: {
                      autoplay: 1,
                      loop: 1,
                      modestbranding: 1,
                    },
                  },
                }}
                muted
                controls={false}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default MobileLandingPageOne;
