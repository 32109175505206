import FlexCol from "@/ui/FlexCol";
import MobileLandingSubPageLayout from "@/ui/MobileLandingSubPageLayout";
import { Typography } from "antd";
import { Collapse, CollapseProps, ConfigProvider } from "antd";
import Image from "next/image";

import { LANDING_DISCORD_LINK, MOBILE_LANDING_ANIMATION_THRESHOLD } from "@/constants";

import styles from "./index.module.less";

type CustomCollapseProps = CollapseProps & {};

const CustomCollapse: React.FC<CustomCollapseProps> = props => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentBg: "#fff",
            headerBg: "#fff",
            contentPadding: "1rem 0",
            headerPadding: "1rem 0",
          },
        },
      }}
    >
      <Collapse
        expandIconPosition="end"
        expandIcon={props => {
          return (
            <Image
              src={"/newimages/public/arr_down.svg"}
              width={12}
              height={12}
              alt="arr_down"
              style={{
                rotate: props.isActive ? "180deg" : "0deg",
              }}
            />
          );
        }}
        bordered={false}
        {...props}
      ></Collapse>
    </ConfigProvider>
  );
};

const collapseItems = [
  {
    key: "1",
    label: (
      <Typography.Title level={3}>
        {`How is Jobright different from other job platforms like LinkedIn?`}
      </Typography.Title>
    ),
    children: (
      <>
        <p>{`The most unique thing about Jobright.ai is that it accompanies you on your job search, unlike LinkedIn and Indeed where you’re on your own.`}</p>
        <p>{`With Jobright, it’s like having an experienced career coach guiding you towards your next dream role. Our platform saves you hours through built-in automation for every step of the job search, making the process more efficient and tailored to your needs.`}</p>
      </>
    ),
  },
  {
    key: "2",
    label: (
      <Typography.Title
        level={3}
      >{`Will Jobright share my personal information?`}</Typography.Title>
    ),
    children: (
      <p>{`No, Jobright takes your privacy seriously. We do not share your personal information with third parties without your consent. Your information is used solely to provide you with AI job matching, resume tailoring, and other personalized services within the Jobright.ai platform.`}</p>
    ),
  },
  {
    key: "3",
    label: <Typography.Title level={3}>{`Is Jobright free to use?`}</Typography.Title>,
    children: (
      <p>{`Yes, all features on Jobright.ai are free to use. We strive to provide every user with equal opportunities for better job search results. However, due to higher service costs, certain features like resume tailoring have daily usage limits. For users seeking more extensive usage, we also offer a paid option for unlimited access.`}</p>
    ),
  },
  {
    key: "4",
    label: (
      <Typography.Title level={3}>{`Where do Jobright's job listings come from?`}</Typography.Title>
    ),
    children: (
      <>
        <p>{`Our job listings primarily come from the career sites of hundreds of thousands of companies, ensuring that you have access to a wide range of opportunities. Additionally, we aggregate the latest job postings from major job platforms, so you don’t have to switch between multiple sites.`}</p>
        <p>{`Jobright continuously screens all new job postings, removing fake listings to ensure a reliable and trustworthy job search experience for you.`}</p>
      </>
    ),
  },
  {
    key: "5",
    label: (
      <Typography.Title level={3}>{`What regions does Jobright's service cover?`}</Typography.Title>
    ),
    children: (
      <p>{`Our primary service is currently within the United States. Additionally, we offer job search options that support H1B visas and remote working. We’re continuously expanding into new regions and will keep our users informed as we grow.`}</p>
    ),
  },
  {
    key: "6",
    label: <Typography.Title level={3}>{`I have more questions!`}</Typography.Title>,
    children: (
      <p>
        Please contact{" "}
        <a className={styles["link"]} href="mailto:support@jobright.ai">
          support@jobright.ai
        </a>{" "}
        or join our discord (
        <a className={styles["link"]} href={LANDING_DISCORD_LINK} target="_blank">
          {LANDING_DISCORD_LINK}
        </a>
        ) if you need any help or have questions or feedback for us. Thanks for joining this journey
        with us!
      </p>
    ),
  },
];

const MobileLandingPageSix = () => {
  return (
    <MobileLandingSubPageLayout
      containerClassName={styles["sixth-page-container"]}
      className={styles["sixth-page-layout"]}
      threshold={MOBILE_LANDING_ANIMATION_THRESHOLD}
    >
      <FlexCol className={styles["main-content"]} size={"1.5rem"}>
        <Typography.Title level={2} className={styles["main-content-title"]}>
          Frequently asked questions
        </Typography.Title>

        <FlexCol className={styles["main-content-sub-container"]} size={"0.5rem"}>
          <CustomCollapse
            className={styles["main-content-collapse"]}
            collapsible="header"
            accordion
            defaultActiveKey={["1"]}
            items={collapseItems}
          />
        </FlexCol>
      </FlexCol>
    </MobileLandingSubPageLayout>
  );
};

export default MobileLandingPageSix;
