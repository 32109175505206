import BasicButton from "@/ui/BasicButton";
import FlexCol from "@/ui/FlexCol";
import MobileLandingSubPageLayout from "@/ui/MobileLandingSubPageLayout";
import SimpleButton from "@/ui/SimpleButton";
import WrapperedIcon from "@/ui/WrapperedIcon";
import { Typography } from "antd";
import dynamic from "next/dynamic";
import { useCallback, useState } from "react";

import { trackEvent } from "@/utils";

import { MOBILE_LANDING_ANIMATION_THRESHOLD } from "@/constants";

import styles from "./index.module.less";

const MobileSignInModal = dynamic(() => import("@/components/Landing/MobileSignInModal"), {
  ssr: false,
});
const MobileSignUpModal = dynamic(() => import("@/components/Landing/MobileSignUpModal"), {
  ssr: false,
});

const MobileLandingPageSeven = () => {
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const onShowSignUpModal = useCallback(() => {
    setSignUpModalOpen(true);
    trackEvent("mobile_landing_2nd_cta_click", {
      scene: "main_landing",
    });
  }, []);

  const onCloseSignInModal = useCallback(() => {
    setSignInModalOpen(false);
  }, []);

  const onCloseSignUpModal = useCallback(() => {
    setSignUpModalOpen(false);
  }, []);

  return (
    <>
      <MobileSignUpModal
        open={signUpModalOpen}
        onCancel={onCloseSignUpModal}
        onClickSignIn={() => {
          setSignUpModalOpen(false);
          setSignInModalOpen(true);
        }}
      />

      <MobileSignInModal
        open={signInModalOpen}
        onCancel={onCloseSignInModal}
        onClickSignUp={() => {
          setSignInModalOpen(false);
          setSignUpModalOpen(true);
        }}
      />
      <MobileLandingSubPageLayout
        containerClassName={styles["seventh-page-container"]}
        className={styles["seventh-page-layout"]}
        threshold={MOBILE_LANDING_ANIMATION_THRESHOLD}
      >
        <FlexCol className={styles["main-content"]} size={"1.5rem"}>
          <WrapperedIcon
            src="/newimages/public/rocket.svg"
            alt="rocket"
            outer={"5rem"}
            radius={40}
            inner={40}
          />

          <Typography.Title level={2} className={styles["main-content-title"]}>
            Ensure a Fast and Successful Journey to Your Next Career Move
          </Typography.Title>

          <SimpleButton
            type="primary"
            onClick={onShowSignUpModal}
            className={styles["mobile-sign-up-button"]}
          >
            Try Jobright for Free
          </SimpleButton>
        </FlexCol>
      </MobileLandingSubPageLayout>
    </>
  );
};

export default MobileLandingPageSeven;
