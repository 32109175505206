import FlexCol from "@/ui/FlexCol";
import FlexRow from "@/ui/FlexRow";
import MobileLandingSubPageLayout from "@/ui/MobileLandingSubPageLayout";
import { Carousel, Typography } from "antd";
import Image from "next/image";

import { SECOND } from "@/constants/jobs";

import styles from "./index.module.less";

const chatItems = [
  [
    {
      paragraph:
        "I am able to find more relevant jobs faster, since using Jobright I have tripled my interview rate. I am truly impressed. ",
      name: "Fred H.",
      avatar: "/newimages/landing/fh.jpeg",
      title: "Senior Software Engineer",
    },
    {
      paragraph:
        "Thanks to this platform I’ve landed a few interviews and accepted an offer within 1 week of interviewing!!",
      name: "Tracy C.",
      avatar: "/newimages/landing/tc.jpeg",
      title: "Sr. Digital Marketing Manager",
    },
    {
      paragraph:
        "You must check out Jobright. It has been saving me hours in my job search! I’m blown away at how easy it is to use!!",
      name: "Tyler S.",
      avatar: "/newimages/landing/ts.jpeg",
      title: "Instructional Designer",
    },
  ],
  [
    {
      paragraph:
        "I’ve enjoyed seeing so many matching jobs. This has completely revamped my job search process. Excited to keep exploring!",
      name: "Chelsea L.",
      avatar: "/newimages/landing/cl.jpeg",
      title: "Senior Recruiter",
    },
    {
      paragraph:
        "It’s a 10/10!! Especially the resume editor which helps me very easily write the content to match the job description. The AI guidance and support has been game changing. Loving it so far!",
      name: "Brandi G.",
      avatar: "/newimages/landing/bg.jpeg",
      title: "Software Engineer",
    },
    {
      paragraph:
        "Not only does jobright show you the most relevant jobs it ALSO helps you network and get potential referrals! The matching system uses my experience, skills, and so much more to find the best fit. Definitely recommend!",
      name: "Gabriella B.",
      avatar: "/newimages/landing/gb.jpeg",
      title: "LinkedIn Strategist",
    },
  ],
];

const ChatItem = ({
  paragraph,
  name,
  avatar,
  title,
}: {
  paragraph: string;
  name: string;
  avatar: string;
  title: string;
}) => {
  return (
    <FlexCol className={styles["chat-item"]} size={"0.75rem"}>
      <Image src={"/newimages/public/quote.svg"} width={16} height={16} alt="quote" />

      <Typography.Paragraph className={styles["chat-item-paragraph"]}>
        {paragraph}
      </Typography.Paragraph>

      <FlexRow size={"0.25rem"}>
        <Image className={styles["chat-avatar"]} src={avatar} width={24} height={24} alt="user" />
        <Typography.Text className={styles["chat-username"]}>{name}</Typography.Text>
        <Typography.Text className={styles["chat-job-title"]}>/</Typography.Text>
        <Typography.Text className={styles["chat-job-title"]}>{title}</Typography.Text>
      </FlexRow>
    </FlexCol>
  );
};

const MobileLandingPageThree = () => {
  return (
    <MobileLandingSubPageLayout
      containerClassName={styles["third-page-container"]}
      className={styles["third-page-layout"]}
      threshold={0.0001}
    >
      <FlexCol className={styles["main-content"]} size={"0.75rem"}>
        <Typography.Title level={2} className={styles["main-content-title"]}>
          500,000+ happy users&apos; love
        </Typography.Title>

        <FlexCol size={"0.75rem"} className={styles["main-content-sub-container"]}>
          <Image src={"/newimages/landing/love_landing.svg"} width={40} height={40} alt="love" />
          <Typography.Paragraph className={styles["main-content-subtitle"]}>
            Thank you for your praise and suggestions. With your support, we can go further. We hope
            to accompany you throughout your job search journey.
          </Typography.Paragraph>
        </FlexCol>

        <Carousel
          className={styles["chat-item-carousel"]}
          effect="fade"
          infinite
          autoplay
          autoplaySpeed={SECOND * 3}
          dots={{
            className: styles["carousel-dots"],
          }}
        >
          {chatItems.map((items, index) => {
            return (
              <div key={index}>
                <FlexCol size={"0.5rem"} className={styles["chat-container"]}>
                  {items.map((item, index) => {
                    return <ChatItem key={index} {...item} />;
                  })}
                </FlexCol>
              </div>
            );
          })}
        </Carousel>
      </FlexCol>
    </MobileLandingSubPageLayout>
  );
};

export default MobileLandingPageThree;
