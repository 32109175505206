import { LandingPageFive } from "@/types/jobs";
import FlexCol from "@/ui/FlexCol";
import RecommendLinks from "@/ui/RecommendVisitorLinks";
import { Typography } from "antd";
import cs from "classnames";

import styles from "./index.module.less";

const MobileLandingPageEight: React.FC<LandingPageFive> = ({ recommendLinks }) => {
  return (
    <section className={cs(styles["eighth-page-container"])}>
      <div className={styles["main-content"]}>
        <Typography.Title level={2} className={styles["main-title"]}>
          Check Out Our Popular Job Lists
        </Typography.Title>
        <RecommendLinks
          className={styles["recommend-links"]}
          recommendLinks={recommendLinks}
          isMobile
        />
      </div>
    </section>
  );
};

export default MobileLandingPageEight;
